ready( function() {
	var langWrapper = document.querySelector('.wrapper-lang');
	if(langWrapper) {
		var langMenu = langWrapper.querySelector('ul.languages');
		if(!langMenu.querySelector('li.active')) {
			langMenu.querySelector('li').classList.add('active');
		}

		document.querySelector('span.toggle-lang').addEventListener('click', function(evt) {
			evt.preventDefault();
			//console.log("click", langWrapper);
			console.log(langWrapper.classList.toggle('active'));
		});
	}
});