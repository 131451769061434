// Define a convenience method and use it
var ready = (callback) => {
	if (document.readyState != "loading") callback();
	else document.addEventListener("DOMContentLoaded", callback);
}
ready(() => { 
	var detailer = document.querySelector(".detailer-module");
	if(detailer == null) {
		return;
	}
	
	var detailerControllerLinks = detailer.querySelector(".detailer-controls").querySelectorAll('a');
	var detailerViews = detailer.querySelector(".detailer-views").querySelectorAll('li');
	var detailerPointLinks = detailer.querySelector(".detailer-views").querySelectorAll('a');
	var detailerViewsZoomed = detailer.querySelector(".detailer-views-zoomed").querySelectorAll('li');
	var detailerViewsZoomedCloseBtns = detailer.querySelector(".detailer-views-zoomed").querySelectorAll('span.close');

	detailerControllerLinks.forEach( function(link) {
		link.addEventListener("click", function(event) {
			event.preventDefault();
			detailerControllerLinks.forEach( function(el) {
				el.classList.remove('active');
			});
			event.target.classList.add('active');

			var view = event.target.dataset.view;

			detailerViews.forEach( function(viewElement) {
				viewElement.classList.remove('active');
				if(viewElement.dataset.view == view) {
					viewElement.classList.add('active');
				}
			});
		});
	});

	detailerPointLinks.forEach( function(link) {
		link.addEventListener("click", function(event) {
			event.preventDefault();
			var point = event.target.dataset.point;
			detailerViewsZoomed.forEach( function(viewElement) {
				viewElement.classList.remove('active');
				if(viewElement.dataset.point == point) {
					viewElement.classList.add('active');
				}
			});
		});
	});

	detailerViewsZoomedCloseBtns.forEach( function(btn) {
		btn.addEventListener("click", function(event) {
			event.preventDefault();
			detailerViewsZoomed.forEach( function(viewElement) {
				viewElement.classList.remove('active');
			});
		});
	})

	if(detailerControllerLinks !== null) {
		detailerControllerLinks[0].click();
	}
});