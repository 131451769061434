function refreshCss() {
	let _links = document.querySelectorAll('link');
	_links.forEach( function(link) {
		if (link.getAttribute('rel') == 'stylesheet') {
			let href = link.getAttribute('href').split('?')[0];
			let newHref = href + '?version=' + new Date().getMilliseconds();
			link.setAttribute('href', newHref);
			console.log("REFRESHED");
		}
	});
}