ready( function() {
	var captchas = document.querySelectorAll('.captcha');
	if(captchas.length > 0) {
		captchas.forEach( function(captcha) {
			//let parent = captcha.
			let img = captcha.querySelector('img');
			let btn = captcha.querySelector('button');
			btn.addEventListener('click', function(evt) {
				evt.preventDefault();
				let _btn = evt.target;
				_btn.disabled = true;
				let url = _btn.attributes.link.value;
				let fd = new FormData();
				fd.append('identifier', _btn.dataset.identifier);
				fd.append('length', _btn.dataset.length);
				fetch(url, {
					method : 'post',
					body: fd
				})
				.then( function(response) {
					return response.text();
				})
				.then( function(text) {
					_btn.disabled = false;
					img.src = text;
				});
			});
		});
	}
});