ready( function() {

	function isVideoHash(str) {  
		var pos = str.indexOf("#");
		console.log(str);
		if (pos != -1) {
			var hash = str.substring(str.length - 5, str.length);
			console.log(hash);
			if(hash == "video") {
				return true;
			}
		}
		return false;
	}

	var productSingle = document.querySelector('.product-single');
	if(productSingle) {

		var hasVideoEl = productSingle.querySelector('span.has-video');

		
		if(hasVideoEl) {
			hasVideoEl.addEventListener("click", function(evt) {
				evt.preventDefault();
				var video = productSingle.querySelector('.tab-videos');
				if(video) {
					var y = window.pageYOffset + video.getBoundingClientRect().top;
					var headerHeight = document.querySelector('header').offsetHeight;
					var top = y;//(y - headerHeight);
					window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
				}
			});
		}
		
		if(hasVideoEl && location.hash == "#video") {
			hasVideoEl.dispatchEvent(new Event("click"));
		}

		window.addEventListener("hashchange", function(evt) {
			if(isVideoHash(evt.oldURL)) {
				evt.stopPropagation();
				evt.preventDefault();
				hasVideoEl.dispatchEvent(new Event("click"));
				return false;
			}
		});
	}
});