$( function() {
	$('.pagination .btn').on('click', function( evt ) {
		if($(this).hasClass('inactive')) {
			try {
				evt.preventDefault();
			}
			catch(e) {}
			return false;
		}
	});
});