ready( function() {
	function loadPopupSizes() {
		let popup = document.querySelector('#popupSizes');
		if(popup) {
			togglePopup();
		}
		else {
			const data = new FormData();
			data.append('action', 'popup_sizes');

			fetch(ajax_object.ajax_url, {
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.text())
			.then((html) => {
				var parser = new DOMParser();
				var doc = parser.parseFromString(html, 'text/html');
				doc.querySelector('.popupSizes__click').addEventListener('click', function(evt) {
					closePopup();
				});
				doc.querySelector('.popupSizes__close').addEventListener('click', function(evt) {
					closePopup();
				});
				document.querySelector('body').appendChild(doc.querySelector('#popupSizes'));
				openPopup();

				
			})
			.catch((error) => {
				console.error(error);
			});
		}
	}

	function openPopup() {
		document.querySelector('body').classList.add('popupSizesActive');
	}

	function closePopup() {
		document.querySelector('body').classList.remove('popupSizesActive');
	}

	function togglePopup() {
		document.querySelector('body').classList.toggle('popupSizesActive');
	}

	let spanShowSizes = document.querySelector('span.show-sizes');

	if(spanShowSizes) {
		spanShowSizes.addEventListener('click', function(evt) {
			evt.preventDefault();
			loadPopupSizes();
		});
	}
});