function setupProductGallery() {
	var fullsize_active = false;
	var zoom_active = false;
	var _imageCache = {};

	var productWrapper = document.querySelector('.product-single');
	var gallery;
	var galleryWrapper;


	var searchLocalLink;
	var baseUrlDealerSearch;

	console.log("gallery loading");

	if(document.querySelector('.product-single') !== null) {
		gallery = productWrapper.querySelector('.product-images');
		galleryWrapper = productWrapper.querySelector('.product-images-wrapper');
		searchLocalLink = productWrapper.querySelector('a.search-local');
		baseUrlDealerSearch = searchLocalLink.dataset.baseurl;
		init();
	}

	function init() {
		galleryWrapper.classList.add('v2');
		productWrapper.querySelector('.variation-colors').querySelectorAll('a').forEach( function(el, key) {
			el.addEventListener('click', function(evt) {
				evt.preventDefault();
				switchVariation(evt.target);
			});
		});

		if(productWrapper.querySelector('.variation-colors').querySelectorAll('a') !== null) {
			productWrapper.querySelector('.variation-colors').querySelector('a').dispatchEvent( new Event("click"));
		}
		else {
			gallery.querySelectorAll('li').classList.add('active_variation');
			if(gallery.querySelectorAll('li.active_variation').length <= 4) {
				galleryWrapper.querySelector('.product-images-show-more').classList.add('disabled');
			}
			else {
				galleryWrapper.querySelector('.product-images-show-more').classList.remove('disabled');
			}
		}

		gallery.querySelectorAll('li').forEach( function(item, index) {
			item.addEventListener('click', function(evt) {
				//evt.preventDefault();
				//console.log("li1 event");
				if(fullsize_active || zoom_active) {
					//console.log("li1 event exit");
					return false;
				}
				//console.log("li1 event succeed");
				gallery.querySelectorAll('li').forEach( function(item2, index2) {
					item2.classList.remove('active');
				});
				item.classList.add('active');
			});
		});

		gallery.querySelector('li').classList.add('active');

		var compareBtn = productWrapper.querySelector('.variations-compare');
		if(compareBtn) {
			compareBtn.addEventListener('click', function(evt) {
				evt.preventDefault();
				compare();
			});
		}

		productWrapper.querySelector('.shoplink').addEventListener('click', function(evt) {
			if(evt.target.classList.contains('shoplink-disabled')) {
				evt.preventDefault();
			}
		});

		galleryWrapper.querySelector('.product-images-close-fullsize').addEventListener('click', function(evt) {
			evt.preventDefault();
			closeFullscreen();
		});

		galleryWrapper.querySelector('.product-images-controls.next').addEventListener('click', function(evt) {
			evt.preventDefault();
			if(!zoom_active) {
				controlFullscreen(1);
			}
		});

		galleryWrapper.querySelector('.product-images-controls.prev').addEventListener('click', function(evt) {
			evt.preventDefault();
			if(!zoom_active) {
				controlFullscreen(-1);
			}
		});

		galleryWrapper.querySelector('.product-images-show-more').addEventListener('click', function(evt) {
			evt.preventDefault();
			galleryWrapper.classList.add('expanded');
		});

		galleryWrapper.querySelector('.product-images-show-less').addEventListener('click', function(evt) {
			evt.preventDefault();
			galleryWrapper.classList.remove('expanded');

			var galleryWrapperBounds = galleryWrapper.getBoundingClientRect();

			skrollTop.scrollTo({
				to:  galleryWrapperBounds.top + window.scrollY - document.querySelector('header').offsetHeight,
				duration: 1000
			});
		});

		gallery.querySelectorAll('li').forEach( function(el) {
			el.addEventListener('click', function(evt) {
				if(zoom_active) {
					//return false;
					galleryWrapper.classList.remove("fullscreen_zoom");
					zoom_active = false;
				}
				else {
					initActiveTile(evt.currentTarget);
				}
			});
		});

	}

	function switchVariation(el) {
		galleryWrapper.classList.remove('compare');
		var li = el.parentElement;
		var sku = li.dataset.sku+"";
		var _url = new URL(baseUrlDealerSearch);
		var webshopurl = li.dataset.webshopurl;

		var i = sku.indexOf('-');
		if(i != -1) {
			sku = sku.substring(i, -1);
		}
		_url.searchParams.append('pid', sku);
		searchLocalLink.setAttribute('href', _url.href);
		if(productWrapper.querySelector('#toshop') !== null) {
			if(!webshopurl) {
				productWrapper.querySelector('#toshop').href = "#";
				document.querySelector('#toshop').classList.add("shoplink-disabled");
			}
			else {
				productWrapper.querySelector('#toshop').href = webshopurl;
				document.querySelector('#toshop').classList.remove("shoplink-disabled");
			}
		}
		
		
		li.parentElement.querySelectorAll('li').forEach( function(obj, index) {
			obj.classList.remove('active');
		});
		li.classList.add('active');

		var _sku = li.dataset.skuColor;
		_sku = _sku.replace(/[-a-z]*$/i, "");

		var varColorName = productWrapper.querySelector('.variation-colors-name');
		varColorName.querySelector('._sku').textContent = sku;
		_sku = _sku.replace('.', '');
		varColorName.querySelector('._cname').textContent = li.dataset.cname;
		varColorName.style.display = "block";

		gallery.querySelectorAll('li').forEach(function (item, index) {
			item.classList.remove('active');
			item.classList.remove('active_variation');
			if( item.dataset.color?.match(_sku)  ) {
				item.classList.add('active_variation');
			}
		});

		gallery.querySelector('li.active_variation').classList.add('active');
		
		//productWrapper.querySelector('.shoplink').classList.remove('shoplink-disabled');

		galleryWrapper.classList.remove('expanded');
		if(gallery.querySelectorAll('li.active_variation').length <= 4) {
			galleryWrapper.querySelector('.product-images-show-more').classList.add('disabled');
		}
		else {
			galleryWrapper.querySelector('.product-images-show-more').classList.remove('disabled');
		}
	}

	function compare() {
		galleryWrapper.classList.add('compare');
		productWrapper.querySelector('.variation-colors').querySelectorAll('li').forEach( function(item, index) {
			item.classList.remove('active');
		});

		var galleryItems = gallery.querySelectorAll('li');
		galleryItems.forEach( function(item, index) {
			item.classList.remove('active_variation');
			var clr = item.dataset?.color;
			if (!clr) {
				return;
			}
			clr = clr.replace(".jpg", "");
			//console.log(clr);
			if(clr.substr(clr.length - 1) == "a") {
				item.classList.add('active_variation');
			}
			/*
			if( (item.dataset.color.substr(item.dataset.color.length - 1) == "a")
				|| !isNaN( item.dataset.color.substr(item.dataset.color.length - 1)) ) {
				
				item.classList.add('active_variation');
			}*/
		});

		productWrapper.querySelector('.shoplink').classList.add('shoplink-disabled');
		galleryWrapper.classList.remove('expanded');
		productWrapper.querySelector('.variation-colors-name').style.display = "none";

		if(gallery.querySelectorAll('li.active_variation').length <= 4) {
			galleryWrapper.querySelector('.product-images-show-more').classList.add('disabled');
		}
		else {
			galleryWrapper.querySelector('.product-images-show-more').classList.remove('disabled');
		}
	}

	function closeFullscreen() {
		productWrapper.querySelectorAll('li').forEach( li => {
			li.classList.remove('active');
		});
		galleryWrapper.classList.remove('fullscreen');
		fullsize_active = false;
		galleryWrapper.classList.remove('fullscreen_zoom');
		zoom_active = false;
		galleryWrapper.classList.add('regular');
	}

	function controlFullscreen(direction) {
		//console.log("ctrl");
		if(zoom_active) {
			return;
		}
		var active = gallery.querySelector('li.active');
		var next;
		if (direction == 1) {
			next = active.nextElementSibling;
			if (!next) {
				next = gallery.querySelector('li');
			}
			/*
			if (!next.classList.contains('image')) {
				return controlFullscreen(1);
			}*/
		}
		else if (direction == -1) {
			next = active.previousElementSibling;
			if (!next) {
				next = gallery.lastElementChild;
			}
			/*
			if (!next.classList.contains('image')) {
				return controlFullscreen(-1);
			}*/
		}
		gallery.querySelectorAll('li').forEach( function(item) {
			item.classList.remove('active');
		});
		initActiveTileFullscreen(next);
		if (zoom_active) {
			if (next.classList.contains('image')) {
				initActiveTileZoom(next);
			}
			else if (next.classList.contains('video')) {
				initActiveTileFullscreen(next);
			}

		}
		next.classList.add('active');
	}

	
	function loadImage(src, prefix) {
		//console.log("loadImage "+prefix, src);
		var src_index = src.replace(/[\W_]+/g,"_");
		var _img = {};
		if (typeof _imageCache[prefix+src_index] === "undefined") {
			//console.log("loading fresh");
			_img = new Image();
			_img.src = src;
			_img.classList.add("loading");
			_img.onload = function() {
				this.classList.remove('loading');
				if(prefix == "fullscreen-zoom") {
					this.style.marginLeft = 0 - (this.offsetWidth / 2) + "px";
				}
			};
			_imageCache[prefix+src_index] = _img;
		}
		else {
			//console.log("loading from cache "+src_index);
			_img = _imageCache[prefix+src_index];
		}
		return _img;
	}

	function initActiveTileFullscreen(li) {
		if (li.classList.contains('video')) {
			// load video
			li.querySelector('video').src = li.querySelector('video').dataset.hires || li.querySelector('video').dataset.lores;
		}
		else if (li.classList.contains('image') && li.querySelector('img.fullscreen') == null) {
			var _img = loadImage(li.querySelector('img.regular').dataset.sizeFullscreen, 'fullscreen');
			_img.classList.add('fullscreen');
			li.querySelector('div').appendChild(_img);
		}
	}

	function initActiveTileZoom(li) {
		//console.log("li:", li);
		if (li.classList.contains('video')) {
			return;
		}
		if(li.querySelector('img.fullscreen_zoom') == null) {
			_img = loadImage(li.querySelector('img.regular').dataset.sizeFullscreenZoom, 'fullscreen-zoom');
			_img.classList.add('fullscreen_zoom');
			li.querySelector('div').appendChild(_img);
		}
	}

	function resetImagePositions(gallery) {
		var imgs = gallery.querySelectorAll('img.fullscreen_zoom');
		imgs.forEach( function(item, index) {
			//item.style.left = 0;
			//item.style.top = 0;
		});
	}

	function initActiveTile(li) {
		gallery.querySelectorAll('li').forEach( function(_li) {
			_li.classList.remove('active');
		});
		li.classList.add('active');

		if(!fullsize_active) {
			galleryWrapper.classList.add('fullscreen');
			galleryWrapper.classList.remove('regular');
			fullsize_active = true;
			//console.log(gallery);
			initActiveTileFullscreen(gallery.querySelector('li.active'));
			return;
		}
		if(!zoom_active) {
			console.log(li);
			if (li.classList.contains('video')) {
				console.log('do not zoom');
				return;
			}
			zoom_active = true;
			initActiveTileFullscreen(gallery.querySelector('li.active'));
			initActiveTileZoom(gallery.querySelector('li.active'));
			galleryWrapper.classList.add('fullscreen_zoom');
			galleryWrapper.classList.remove('regular');

			var wrapper = gallery.querySelector('li.active')
			var zoomed = wrapper.querySelector('img.fullscreen_zoom');
			zoomed.style.position = "absolute";
			zoomed.style.width = "auto";
			zoomed.style.height = "auto";
			zoomed.marginLeft = 0 - (zoomed.offsetWidth / 2) + "px";

			zoomed.addEventListener("load", function(evt) {
				var wrapper_w = wrapper.offsetWidth;
				var wrapper_h = wrapper.offsetHeight;
				var zoomed_w = zoomed.offsetWidth;
				var zoomed_h = zoomed.offsetHeight;
				var cursor_x = evt.pageX;
				var cursor_y = evt.pageY;

				var currentLi = evt.target.parentElement;

				var factor_y = 1;
				if( wrapper_h > zoomed_h ) {
					//console.log(wrapper_h, zoomed_h);
					factor_y = wrapper_h / zoomed_h;
				}
				else {
					//console.log(wrapper_h, zoomed_h);
					factor_y = zoomed_h / wrapper_h;
				}

				var factor_x = 1;
				if( wrapper_w > zoomed_w ) {
					factor_x = zoomed_w / wrapper_w;
				}
				else {
					factor_x = wrapper_w / zoomed_w;
				}

				//console.log(factor_x, factor_y);

				var _evtHandler = new Hammer( evt.target, { direction: Hammer.DIRECTION_ALL, threshold: 0 });
				var isDragging = false;
				_evtHandler.on('pan', function(ev) {

					

					// for convience, let's get a reference to our object
					var elem = ev.target;


					// change offset to actual top/left values because of position:relative!

					var lastPosX = zoomed.dataset['data-last-pos-x'] ? zoomed.dataset['data-last-pos-x'] : 0;
					var lastPosY = zoomed.dataset['data-last-pos-y'] ? zoomed.dataset['data-last-pos-y'] : 0;

					// DRAG STARTED
					// here, let's snag the current position
					// and keep track of the fact that we're dragging
					if ( ! isDragging ) {
						isDragging = true;
						lastPosX = elem.offsetLeft;
						zoomed.dataset['data-last-pos-x'] = elem.offsetLeft;
						lastPosY = elem.offsetTop;
						zoomed.dataset['data-last-pos-y'] = elem.offsetTop;
					}

					// we simply need to determine where the x,y of this
					// object is relative to where it's "last" known position is
					// NOTE: 
					//    deltaX and deltaY are cumulative
					// Thus we need to always calculate 'real x and y' relative
					// to the "lastPosX/Y"
					var posX = ev.deltaX + lastPosX;
					var posY = ev.deltaY + lastPosY;

					// move our element to that position
					elem.style.marginLeft = posX + "px";
					elem.style.top = posY + "px";

					// DRAG ENDED
					// this is where we simply forget we are dragging
					if (ev.isFinal) {
						isDragging = false;
					}
				});

				croppedVerticalFactor = (gallery.offsetHeight < zoomed_h) ? 1 : -1;
				croppedHorizontalFactor = (gallery.offsetWidth < zoomed_w) ? 1 : -1;
				//zoomed.style.left = "50%";
				
				gallery.addEventListener('mousemove', function(e) {
					e.preventDefault();

					cursor_x = e.clientX;
					cursor_y = e.clientY;

					if(e.type == "touchmove") {
						zoomed.style.marginLeft = 0 + (cursor_x - wrapper_w / 2) * factor_x + "px";
						zoomed.style.top = 0 + (cursor_y - wrapper_h / 2) * factor_y + "px";
					}
					else {
						topPercent = (1.0 / (gallery.offsetHeight / cursor_y));
						zoomed.style.top = (gallery.offsetHeight - zoomed_h) * topPercent * croppedVerticalFactor * 1.1 + "px";

						leftPercent = (1.0 / (gallery.offsetWidth / cursor_x)) - 0.5;
						zoomed.style.marginLeft = 0 - (zoomed_w / 2) + (gallery.offsetWidth - zoomed_w) * leftPercent * croppedHorizontalFactor * 1.1 + "px";
						//console.log(`(${gallery.offsetWidth} - ${zoomed_w}) * ${leftPercent} * ${croppedHorizontalFactor} * 1.1`);
						
					}


				});
			});
		}
		else {
			galleryWrapper.classList.remove('fullscreen_zoom');
			gallery.querySelectorAll('img.fullscreen_zoom').forEach( function(img) {
				img.style.marginLeft = 0 - (img.offsetWidth / 2) + "px";
			});
			zoom_active = false;
		}
	}

}

/*!
 * skrollTop 0.0.1
 * https://github.com/alvarotrigo/skrollTop.js
 * @license MIT
 *
 * Copyright (C) 2018 alvarotrigo.com - A project by Alvaro Trigo
 */
(function (root, factory) {
	if ( typeof define === 'function' && define.amd ) {
	  define([], (function () {
		return factory(root, root.document);
	  }));
	} else if ( typeof exports === 'object' ) {
	  module.exports = factory(root, root.document);
	} else {
	  root.skrollTop = factory(root, root.document);
	}
  })(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, (function (window, document) {
	  'use strict';
  
	  var g_activeAnimation;
	  var self = {};
  
	  //easeInOutCubic animation included in the library
	  Math.easeInOutCubic = function (t, b, c, d) {
		  if ((t/=d/2) < 1) return c/2*t*t*t + b;return c/2*((t-=2)*t*t + 2) + b;
	  };
  
	  self.stop = function(){
		  g_activeAnimation = false;
	  };
  
	  /**
	  * Simulates the animated scrollTop of jQuery. Used when css3:false or scrollBar:true or autoScrolling:false
	  * http://stackoverflow.com/a/16136789/1081396
	  */
	 self.scrollTo = function(params) {
		 var element = typeof params.element !== 'undefined' ? params.element : window;
		 var to = params.to;
		 var duration = typeof params.duration !== 'undefined' ? params.duration : 700;
		 var callback = typeof params.callback !== 'undefined' ? params.callback : null;
		 var easing = typeof params.easing !== 'undefined' ? params.easing : Math.easeInOutCubic;
  
		 var start = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
		 var change = to - start;
		 var currentTime = 0;
		 var increment = 16; //same amount of milliseconds as requestAnimationFrame
		 g_activeAnimation = true;
  
		 var animateScroll = function() {
			  //in case we want to stop it from other function whenever we want
			 if (g_activeAnimation) {
				 currentTime += increment;
				 element.scrollTo(0, easing(currentTime, start, change, duration));
  
				 if (currentTime < duration) {
					 setTimeout(animateScroll, increment);
				 } else if (callback){
					 callback();
				 }
			 }else if (currentTime < duration && callback){
				 callback();
			 }
		 };
  
		 animateScroll();
	 };
  
	 return self;
  }));