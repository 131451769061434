function setupGewinnspiel() {
	var gewinnspielForm = document.querySelector('form[name=gewinnspiel]');
	if(gewinnspielForm) {
		console.log('setting up gewinnspiel');

		gewinnspielForm.addEventListener("submit", function(event) {
			event.preventDefault();
			var data = new FormData(gewinnspielForm);

			gewinnspielForm.querySelectorAll('input, select').forEach( function(el, index) {
				try {
					el.checkValidity();
				}
				catch(e) {
					console.log('catch');
					return false;
				}
			});

			postGewinnspielData( gewinnspielForm.getAttribute('action'), data)
			.then( function(data) {
				if(data.error == false) {
					displayGewinnspielMsg("Danke für Deine Teilnahme! Bitte bestätige Deine Daten mit dem Link in der E-Mail.");
					document.querySelector('.wrapper-gewinnspiel').remove();
				}
				else {
					displayGewinnspielMsg(data.msg);
				}
			});
		});

		const questions = new Map();
		gewinnspielForm.querySelectorAll('input[name^="question"]').forEach( function(el, i) {
			questions.set(el.getAttribute('name'), false);
			el.addEventListener("click", function(event) {
				event.target.dispatchEvent(new Event('answer', {
					bubbles: true,
					detail: {
						name: event.target.getAttribute('name'),
						value: event.target.checked
					}
				}));
			});
		});
		console.log(questions);

		gewinnspielForm.addEventListener('answer', function(event) {
			questions.set(event.target.getAttribute('name'), true);
			if ([ ...questions.values()].every(val => !!val)) {
				var wrapperData = gewinnspielForm.querySelector(".wrapper-data");
				wrapperData.classList.toggle('active', true);
			}
		});

		gewinnspielForm.querySelector("input[name=catalog]")?.addEventListener("change", function(event) {
			event.preventDefault();
			var active = event.target.checked;
			var wrapperCatalog = gewinnspielForm.querySelector(".wrapper-catalog");
			wrapperCatalog.classList.toggle('active', active);
			wrapperCatalog.querySelectorAll('[data-required]').forEach( function(el, i) {
				if(!active) {
					el.removeAttribute("required");
				}
				else {
					el.setAttribute("required", "required");
				}
			});
		});
	}

	var gewinnspielModal = document.querySelector('.gewinnspiel-modal');
	if(gewinnspielModal) {
		gewinnspielModal.querySelector('a.btn-close').addEventListener("click", function(event) {
			event.preventDefault();
			gewinnspielModal.remove();
		});
	}
}

async function postGewinnspielData(url, data) {
	var response = await fetch(url+'?action=gewinnspielSubmitFunction', {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			//'Content-Type': 'application/json'
			//'Content-Type': 'application/x-www-form-urlencoded',
			//'Content-Type': 'multipart/form-data'
		},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
		body: data
	});
	return response.json();
}

function displayGewinnspielMsg(msg) {
	if(Array.isArray(msg)) {
		msg = msg.join('<br />');
	}
	var wrapper = document.createElement('div');
	wrapper.classList.add('wrapper-gewinnspiel');
	wrapper.classList.add('gewinnspiel-modal');
	var wrapperInner = document.createElement('div');
	wrapperInner.classList.add('wrapper-inner');
	var p = document.createElement('p');
	p.innerHTML = msg;
	btn = document.createElement('a');
	btn.addEventListener("click", function(event) {
		event.preventDefault();
		document.querySelector('.gewinnspiel-modal').remove();
	});
	btn.classList.add('btn');
	btn.classList.add('btn-close');
	btn.innerHTML = "Zurück zum Gewinnspiel";
	wrapperInner.append(p, btn);
	wrapper.append(wrapperInner);
	document.querySelector('body').append(wrapper);
}