ready( function() {
	var teaser = document.querySelector('ul.teaser');
	if(teaser) {
		var list = teaser.querySelectorAll('li');
		list.forEach(function(el) {
			var video = el.querySelector('video');
			if(video) {
				video.play();
			}
		});
	}
});