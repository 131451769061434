$( function() {

	$('.to-top > .arrow').on('click', function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
	});

	var _i = 0;
	$('ul.blogteaser').find('li').each( function() {
		var $li = $(this);
		$li.data('slidenum', _i);

		var $btn = $('<span>');
		$btn.data('slidenum', _i);
		$btn.on('click', function() {
			$_btn = $(this);
			$li.closest('ul').find('li').removeClass('active').filter( function() {
				return $(this).data('slidenum') == $_btn.data('slidenum');
			}).addClass('active');
			$('.blogteaser-controls').find('span').removeClass('active');
			$_btn.addClass('active');
		});

		$li.closest('ul').find('.blogteaser-controls').append( $btn ).find('span').first().trigger('click');
		_i++;
	});

	function repositionSliderVideo() {
		$('.slider').find('video').each( function() {
			$video = $(this);
			var vwidth = $(this).width();
			var pwidth = $(this).closest('div').width();
			if( pwidth < vwidth ) {
				$video.css( 'left', Math.round( (pwidth - vwidth) / 2 ));
			}
			else {
				$video.css( 'left', 0);
			}
		});
	}
	repositionSliderVideo();

	$(window).on('resize', repositionSliderVideo);

	/*
	$(window).on('resize', resizeProductsView);
	function resizeProductsView() {
		var $pc = $('.product-cols');
		console.log('resize');
		if($pc) {
			var _h = Math.max( $pc.find('.gallery-active-thumb').height(), $pc.find('h1').height() + $pc.find('.entry-summary').height() );
			$pc.find('.woocommerce-product-gallery').css('max-height', _h);
		}
	}*/
});