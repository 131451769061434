class AjaxForm {
	constructor(form) {
		this.form = form;
		this.form.addEventListener('submit', this.send);
	}

	send(evt) {
		evt.preventDefault();
		let form = evt.target;
		if(form.classList.contains('form-disabled')) {
			return;
		}
		form.classList.add('form-disabled');
		let fData = new FormData(form);
		fData.append('action', form.dataset.ajaxAction);
		let url = new URL(form.getAttribute('action'));
		form.querySelector('input[type=submit]').disabled = true;
		fetch(url, {
			method: 'POST',
			credentials: 'same-origin',
			body: fData
		})
		.then( function(response) {
			return response.json();
		})
		.then( function(data) {
			form.querySelector('input[type=submit]').disabled = false;
			form.classList.remove('form-disabled');
			if(data.errors.length) {
				if(form.querySelector('ul.errors')) {
					form.querySelector('ul.errors').innerHTML = "";
					data.errors.forEach( function(err) {
						let li = document.createElement('li');
						li.innerHTML = err;
						form.querySelector('ul.errors').appendChild(li);
					});
				}
				else {
					console.error('form has no error list.');
				}
			}
			else {
				let msg = document.createElement('h3');
				msg.innerHTML = data.msg;
				form.innerHTML = "";
				form.appendChild(msg);
			}
		})
		.catch( function(error) {
			console.error(error);
		});
	}
}

ready( function() {
	var ajaxForms = [];
	document.querySelectorAll('.ajax-form')
		.forEach( function(form) {
		ajaxForms.push( new AjaxForm(form) );
	});
});