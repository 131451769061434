$( function() {
	//get-search-suggestions/
	/*
	$('input[name=s]').on('focus input change', loadDatalist);

	function loadDatalist(e) {
		var $el = $(e.target);
		var listID = $el.attr('list');
		var $list = $('#'+listID);
		var dlisturl = $list.data('dlist-url');
		$el.off();

		$.ajax(dlisturl, {
			method: 'get',
			dataType: 'html',
			cache: true,
			success: function(res) {
				$list.html( res );
			}
		});
	}
	*/
});