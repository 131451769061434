var sly = [];
var _options = {};
$( function() {
	$('.frame').each( function() {
		console.log('frame found');
		var $frame = $(this);

		if($frame.data('setheight')) {
			var heights = $("div.panel").map( function() {
				return $(this).height();
			}).get();
			var maxHeight = Math.max.apply(null, heights);
			$frame.height( maxHeight );
			$frame.find('li').height( maxHeight );
		}

		var _i = $frame.data('identifier');

		_options = {
			horizontal: 1,
			itemNav: $frame.data('itemnav'),
			speed: 300,
			mouseDragging: 1,
			touchDragging: 1,
			smart:true,
			prev: $frame.parent().find('.prev'),
			next: $frame.parent().find('.next'),
		};

		var evts = {
			load : function() {
				$frame.find('ul.slidee').addClass('loaded');
				sly[_i].activate(0);
			}
		};
		sly[_i] = new Sly( $frame , _options, evts);

		$frame.parent().find('.next').on('click', function(evt) {
			if( $frame.find('.slidee').find('li:last-child').is('.active') ) {
				sly[_i].activate(0);
				evt.stopImmediatePropagation();
			}
		});

		$frame.parent().find('.prev').on('click', function(evt) {
			if( $frame.find('.slidee').find('li:first-child').is('.active') ) {
				sly[_i].activate(sly[_i].items.length - 1);
				evt.stopImmediatePropagation();
			}
		});

		sly[_i].init();

		$( window ).on('resize', function() {
			console.log('ok');
			sly[_i].reload();
		});
	});
});