$( function() {
	if($('.scroller-wrapper').length) {
		var $scroller_wrapper = $('.scroller-wrapper');
		var $scroller = $scroller_wrapper.find('ul');

		var offset;

		

		$scroller_wrapper.find('.scroller-controls').find('a').on('click', function(evt) {
			evt.preventDefault();
			if($(this).hasClass('next')) {
				scrollerNext($scroller);
			}
			if($(this).hasClass('prev')) {
				scrollerPrev($scroller);
			}
		});

		var mc = new Hammer($scroller.get(0));
		mc.on("swipeleft", function(ev) {
			ev.preventDefault();
			scrollerNext($scroller);
		});
		mc.on("swiperight", function(ev) {
			ev.preventDefault();
			scrollerPrev($scroller);
		});
	}

	function calculateOffset() {
		offset = $scroller.find('li').first().outerWidth();
	}

	function scrollerNext($scroller) {
		$scroller.removeClass('transition-next transition-prev');
		calculateOffset();
		var $el;
		$el = $scroller.find('li').first();
		$scroller.addClass('transition-next').append($el);
	}

	function scrollerPrev($scroller) {
		$scroller.removeClass('transition-next transition-prev');
		calculateOffset();
		var $el;
		$el = $scroller.find('li').last();
		$scroller.addClass('transition-prev').prepend($el);
	}
	
});