$( function() {
	if( $('ul.uitabs') ) {
		$('ul.uitabs').find('h3').on('click', function() {
			$(this).closest('li').toggleClass('active');
		});
	}

	$('a#todealers').on('click', function(e) {
		e.preventDefault();
		if($(this).hasClass('shoplink-disabled')) {
			return;
		}
		$('html, body').animate({
			scrollTop: $('a[name=dealers]').offset().top - $('header').height()
		}, 1000);
		$('a[name=dealers]').closest('li').addClass('active');
	});
});